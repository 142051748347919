import { SVGIcon } from '@lodgelink/lodgelink-components';
import Box from '@mui/material/Box';
import colors from '../../layout/css/colors';
import fonts from '../../layout/css/fonts';
import { H1Container, LogoContainer, StyledH1 } from './NotFound.styles';

const NotFound = () => {
  return (
    <Box sx={{fontFamily: fonts.fontRegular}}>
    <Box
      sx={{
        bgcolor: colors.colorWhite,
        height: '50vh',
        width: '150vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}
    >
      <LogoContainer>
        <SVGIcon selectedIcon="lodgelinkcolor" width="210px" height="70px" />
      </LogoContainer>
      <H1Container>
        <StyledH1 primary>404 Not &nbsp;</StyledH1>
        <StyledH1>Found</StyledH1>
      </H1Container>
    </Box>
  </Box>
  );
};

export default NotFound;
