import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import Box from '@mui/material/Box';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import get from 'lodash.get';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import colors from '../../layout/css/colors';
import {
  ButtonsContainerStyle,
  LoadingStyle,
  ModalStyle,
  otpInputStyle,
  StyledButton,
  TextComponentRowStyle
} from './OTPModal.styles';
import { MUTATION_AUTHENTICATE_OTP, QUERY_GENERATE_OTP } from './OTPModal.gql';
import useToken from '../../hooks/useToken';
import { Link } from '@mui/material';

const getOTPDetails = (data) => {
  const verificationCode = get(data, 'generatePartnerOTP.verificationCode');
  const iv = get(data, 'generatePartnerOTP.iv');
  return {
    verificationCode,
    iv
  };
};

const hasOTP = (otpDetails) => {
  if (otpDetails.retry) return true;
  return !!otpDetails.otp && !!otpDetails.iv && !!otpDetails.verificationCode && !!otpDetails.bookingNumber;
};

const isRetry = (error) => {
  return error.toString().includes('incorrect');
};

const OTPModal = (props) => {
  const { setOTPModalOpen, setVCCModalOpen, otpModalOpen, otpDetails, setOTPDetails } = props;
  const { token } = useToken();
  const emailAddress = localStorage.getItem('emailAddress');
  if (hasOTP(otpDetails) && otpModalOpen !== otpDetails.bookingNumber)
    setOTPDetails({
      otp: '',
      iv: '',
      verificationCode: '',
      bookingNumber: '',
      retry: false
    });
  const {
    error: generationError,
    data: generationData,
    loading: generationLoading
  } = useQuery(QUERY_GENERATE_OTP, {
    variables: { token, bookingNumber: otpModalOpen },
    fetchPolicy: 'cache-and-network',
    skip: hasOTP(otpDetails)
  });
  const [authenticatePartnerOTP, { error: authenticationError, loading: authenticationLoading }] =
    useMutation(MUTATION_AUTHENTICATE_OTP);

  const { verificationCode, iv } = getOTPDetails(generationData);

  const [otp, setOTP] = useState('');
  const handleClose = () => {
    setOTPModalOpen('');
    setOTPDetails({
      ...otpDetails,
      retry: false
    });
  };
  const handleChange = (otp) => setOTP(otp);
  const handleVerify = () => {
    const input = { verificationCode, otp, emailAddress, iv };
    if (otpDetails.retry) {
      input.verificationCode = otpDetails.verificationCode;
      input.iv = otpDetails.iv;
    }
    authenticatePartnerOTP({
      variables: {
        input
      }
    })
      .then(() => {
        setOTPDetails({
          iv: iv ?? otpDetails.iv,
          otp,
          verificationCode: verificationCode ?? otpDetails.verificationCode,
          bookingNumber: otpModalOpen,
          retry: false
        });
        setVCCModalOpen(otpModalOpen);
        setOTPModalOpen('');
      })
      .catch((error) => {
        if (isRetry(error))
          setOTPDetails({
            ...otpDetails,
            iv: iv ?? otpDetails.iv,
            verificationCode: verificationCode ?? otpDetails.verificationCode,
            retry: true
          });
        else
          setOTPDetails({
            ...otpDetails,
            retry: false
          });
      });
  };

  const handleReload = () => {
    const otpModal = otpModalOpen;
    setOTPModalOpen('');
    setTimeout(() => {
      setOTPModalOpen(otpModal);
    }, 10);
  };

  if (hasOTP(otpDetails)) {
    const { otp, iv, verificationCode, bookingNumber, retry } = otpDetails;
    if (bookingNumber === otpModalOpen && !retry) {
      const input = { verificationCode, otp, emailAddress, iv };
      authenticatePartnerOTP({
        variables: {
          input
        }
      })
        .then(() => {
          setVCCModalOpen(otpModalOpen);
          setOTPModalOpen('');
        })
        .catch(() => {
          setOTPDetails({
            iv: '',
            otp: '',
            verificationCode: '',
            bookingNumber: '',
            retry: false
          });
        });
    }
  }

  if (generationLoading || authenticationLoading)
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={LoadingStyle}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );

  if (generationError || authenticationError) {
    const error = generationError ?? authenticationError;
    const isNotFound = error.toString().includes('not found');
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
            Error Occured
          </Typography>
          <Typography id="modal-modal-description" sx={{ m: 2 }} color={colors.colorGrey03} fontWeight="strong">
            {`${error}`}
          </Typography>
          {!isNotFound && (
            <StyledButton primary onClick={handleReload}>
              {otpDetails.retry ? `Retry` : `Request Access`}
            </StyledButton>
          )}
        </Box>
      </Modal>
    );
  }

  return (
    <div>
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={TextComponentRowStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
              Authentication Required&nbsp;
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
              <Link href="#" underline="always" color={colors.colorBlue}>
                {`(why?)`}
              </Link>
            </Typography>
          </Box>
          <Box sx={TextComponentRowStyle}>
            <Typography id="modal-modal-description" color={colors.colorGrey03} fontWeight="strong">
              <span>An email containing the required 6 digit authorization code has been sent to&nbsp;</span>
              <span style={{ color: colors.colorBlue, fontWeight: 'bold' }}>{emailAddress}</span>
            </Typography>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} color={colors.colorGrey03} fontWeight="strong">
            {`
            Please enter the provided code here to access the VCC`}
          </Typography>
          <Box margin="20px">
            <OtpInput inputStyle={otpInputStyle} value={otp} onChange={handleChange} numInputs={6} />
          </Box>
          <Box sx={ButtonsContainerStyle} width={500}>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButton primary onClick={handleVerify}>
              Verify
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

OTPModal.propTypes = {
  setOTPModalOpen: PropTypes.func.isRequired,
  setVCCModalOpen: PropTypes.func.isRequired,
  setOTPDetails: PropTypes.func.isRequired,
  otpModalOpen: PropTypes.string.isRequired,
  otpDetails: PropTypes.shape({
    iv: PropTypes.string,
    otp: PropTypes.string,
    verificationCode: PropTypes.string,
    bookingNumber: PropTypes.string,
    retry: PropTypes.bool
  }).isRequired
};

export default OTPModal;
