import gql from 'graphql-tag';

export const QUERY_GENERATE_OTP = gql`
  query QueryGeneratePartnerOTP($token: String!, $bookingNumber: String!) {
    generatePartnerOTP(token: $token, bookingNumber: $bookingNumber) {
      __typename
      message
      verificationCode
      iv
    }
  }
`;

export const MUTATION_AUTHENTICATE_OTP = gql`
  mutation MutationAuthenticatePartnerOTP($input: AuthenticatePartnerOTPInput!) {
    authenticatePartnerOTP(input: $input) {
      __typename
      message
    }
  }
`;
