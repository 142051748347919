
import styled from 'styled-components';
import colors from '../../layout/css/colors';

export const H1Container = styled.div`
display:flex;
flex-direction: row;
margin-top: 10px;
margin-left: 30px;
margin-right: 30px;
`;

export const LogoContainer = styled.div`
    margin-left: 20px;
`;

export const StyledH1 = styled.h1`
background: white;
color: ${props => props.primary ? colors.logoColor1 : colors.logoColor2};
font-size: ${props => props.authed ? '25px' : '80px'};
font-weight: bold;
`;