import gql from 'graphql-tag';

export const QUERY_SEARCH_BOOKINGS = gql`
  query PartnerHubSearchBookings($token: String!, $searchInput: PartnerBookingSearchInput!) {
    partnerSearchBookings(token: $token, searchInput: $searchInput) {
      __typename
      bookingCount
      bookings {
        __typename
        id
        guests {
          __typename
          firstName
          lastName
        }
        customerName
        bookingNumber
        checkin
        createdAt
      }
    }
  }
`;
