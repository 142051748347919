import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingPageStyle } from './Loading.styles';

const Loading = () => {
  return (
    <Box sx={LoadingPageStyle}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
