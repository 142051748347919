import { Box } from '@mui/material';
import React, { useState } from 'react';
import AdvancedSearch from '../../buildingBlocks/advancedSearch/AdvancedSearch';
import BookingTable from '../../buildingBlocks/bookingTable/BookingTable';
import OTPModal from '../../buildingBlocks/otpModal/OTPModal';
import VCCModal from '../../buildingBlocks/vccModal/VCCModal';

export default function Dashboard() {
  const [vccModalOpen, setVCCModalOpen] = useState('');
  const [otpModalOpen, setOTPModalOpen] = useState('');
  const [searchInput, setSearchInput] = useState({
    top: 9,
    skip: 0,
    guestName: undefined,
    organizationId: undefined,
    bookingId: undefined,
    checkin: undefined,
    createdAt: undefined,
    todayOnly: false
  });
  const [otpDetails, setOTPDetails] = useState({
    otp: '',
    iv: '',
    verificationCode: '',
    bookingNumber: '',
    retry: false
  });
  const [page, setPage] = useState(0);
  const initialBookingNumber = localStorage.getItem('bookingNumber');

  return (
    <>
      <AdvancedSearch
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        initialBookingNumber={initialBookingNumber}
        setPage={setPage}
      />
      <BookingTable
        setOpen={setOTPModalOpen}
        setVCCOpen={setVCCModalOpen}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        otpDetails={otpDetails}
        page={page}
        setPage={setPage}
      />
      {vccModalOpen && !otpModalOpen && <VCCModal setVCCModalOpen={setVCCModalOpen} bookingNumber={vccModalOpen} />}
      {otpModalOpen && (
        <OTPModal
          otpDetails={otpDetails}
          setOTPDetails={setOTPDetails}
          setOTPModalOpen={setOTPModalOpen}
          otpModalOpen={otpModalOpen}
          setVCCModalOpen={setVCCModalOpen}
        />
      )}
    </>
  );
}
