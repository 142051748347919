import gql from 'graphql-tag';

export const QUERY_GET_VCC_DETAILS = gql`
  query PartnerGetVCCDetails($token: String!, $bookingNumber: String!) {
    partnerGetVCCDetails(token: $token, bookingNumber: $bookingNumber) {
      __typename
      guestName
      customerName
      bookingNumber
      duration
      cardholderName
      cardNumber
      expiry
      cvv
    }
  }
`;
