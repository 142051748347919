import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import colors from '../../layout/css/colors';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  ControlBoxStyle,
  InputBoxStyle,
  SearchBodyStyle,
  SearchButtonsContainerStyle,
  SearchHeaderStyle,
  StyledButton,
  StyledH1
} from './AdvancedSearch.styles';
import GraphAutoComplete from '../graphAutoComplete/GraphAutoComplete';
import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKING_GUEST,
  SEARCH_CONFIRMATION_NUMBER,
  SEARCH_CUSTOMERS
} from './AdvancedSearch.gql';

const returnSearchInput = (values) => {
  let guestName, organizationId, bookingId, checkin, createdAt, todayOnly;
  if (values.confirmationNumber && values.confirmationNumber.label) bookingId = values.confirmationNumber.id;
  else if (values.bookingNumber && values.bookingNumber.label) bookingId = values.bookingNumber.id;
  else bookingId = 0;

  guestName = values.guestName ? values.guestName.label : '';
  organizationId = values.customerName ? values.customerName.id : 0;
  checkin = values.checkin ? values.checkin.format('YYYY-MM-DD') : null;
  createdAt = values.createdAt ? values.createdAt.format('YYYY-MM-DD') : null;
  todayOnly = values.todayOnly ? values.todayOnly : false;

  return {
    guestName,
    organizationId,
    bookingId,
    checkin,
    createdAt,
    todayOnly
  };
};

const AdvancedSearch = (props) => {
  const { searchInput, setSearchInput, setPage } = props;
  const [reset, setReset] = React.useState(false);
  const [initialBookingNumber, setInitialBookingNumber] = React.useState(props.initialBookingNumber);
  const toggleReset = () => {
    setReset(Math.random());
  };
  const formik = useFormik({
    initialValues: {
      guestName: {
        id: 0,
        label: ''
      },
      customerName: {
        id: 0,
        label: ''
      },
      bookingNumber: {
        id: 0,
        label: ''
      },
      confirmationNumber: {
        id: 0,
        label: ''
      },
      checkin: null,
      createdAt: null,
      todayOnly: false
    },
    onSubmit: (values) => {
      setPage(0);
      setSearchInput({
        ...searchInput,
        ...returnSearchInput(values)
      });
    },
    onReset: () => {
      setPage(0);
      setSearchInput({
        ...searchInput,
        guestName: undefined,
        organizationId: 0,
        bookingId: 0,
        checkin: undefined,
        createdAt: undefined,
        todayOnly: false
      });
      toggleReset();
    }
  });

  React.useEffect(() => {
    if (initialBookingNumber && formik.values.bookingNumber && formik.values.bookingNumber.label)
      setSearchInput({
        ...searchInput,
        bookingId: formik.values.bookingNumber.id
      });
  }, [formik.values.bookingNumber]);

  return (
    <Box bgcolor={colors.colorWhite} margin="10px" marginTop={'50px'}>
      <Box sx={SearchHeaderStyle} height={'40px'} bgcolor={colors.colorBurgundy}>
        <StyledH1 color={colors.colorWhite}>Search</StyledH1>
      </Box>
      <Box component="form" noValidate autoComplete="off" bgcolor={colors.colorWhite} sx={SearchBodyStyle}>
        <Box sx={InputBoxStyle}>
          <GraphAutoComplete
            query={SEARCH_BOOKING_GUEST}
            queryString={'partnerGuestTypeahead'}
            inputName={'guestName'}
            label={'Guest Name'}
            key={reset}
            setValue={(value) => formik.setFieldValue('guestName', value)}
            mapper={(data) =>
              data.map((result) => ({ id: result.id, label: result.firstName + ' ' + result.lastName }))
            }
          />
          <GraphAutoComplete
            query={SEARCH_CUSTOMERS}
            queryString={'partnerCustomerTypeahead'}
            inputName={'customerName'}
            label={'Company Name'}
            key={reset + 1}
            setValue={(value) => formik.setFieldValue('customerName', value)}
            mapper={(data) => data.map((result) => ({ id: result.id, label: result.name }))}
          />
          <GraphAutoComplete
            query={SEARCH_BOOKINGS}
            queryString={'partnerBookingTypeahead'}
            inputName={'bookingNumber'}
            label={'Booking Number'}
            key={reset + 2}
            disabled={formik.values.confirmationNumber && formik.values.confirmationNumber.label}
            setValue={(value) => formik.setFieldValue('bookingNumber', value)}
            mapper={(data) => data.map((result) => ({ id: result.id, label: result.bookingNumber }))}
            initialValue={initialBookingNumber}
          />
          <GraphAutoComplete
            query={SEARCH_CONFIRMATION_NUMBER}
            queryString={'partnerConfirmationNumberTypeahead'}
            inputName={'confirmationNumber'}
            label={'Confirmation Number'}
            key={reset + 3}
            disabled={formik.values.bookingNumber && formik.values.bookingNumber.label}
            setValue={(value) => formik.setFieldValue('confirmationNumber', value)}
            mapper={(data) => data.map((result) => ({ id: result.id, label: result.confirmationNumber }))}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Checkin Date"
              inputFormat="YYYY-MM-DD"
              value={formik.values.checkin}
              disabled={formik.values.todayOnly}
              onChange={(date) => formik.setFieldValue('checkin', date)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="Created"
              inputFormat="YYYY-MM-DD"
              value={formik.values.createdAt}
              onChange={(date) => formik.setFieldValue('createdAt', date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={ControlBoxStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.todayOnly}
                onChange={(event) => {
                  formik.setFieldValue('todayOnly', event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={`Show Today's Bookings Only`}
          />
          <Box sx={SearchButtonsContainerStyle}>
            <StyledButton
              onClick={() => {
                formik.handleReset();
                formik.resetForm();
                setInitialBookingNumber('');
              }}
            >
              Clear Search
            </StyledButton>
            <StyledButton onClick={() => formik.handleSubmit()} primary>
              Submit
            </StyledButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AdvancedSearch.propTypes = {
  searchInput: PropTypes.shape({
    top: PropTypes.number,
    skip: PropTypes.number,
    guestName: PropTypes.string,
    organizationId: PropTypes.number,
    bookingId: PropTypes.number,
    checkin: PropTypes.string,
    createdAt: PropTypes.string,
    todayOnly: PropTypes.bool
  }).isRequired,
  setSearchInput: PropTypes.func.isRequired,
  initialBookingNumber: PropTypes.string
};

AdvancedSearch.defaultProps = {
  initialBookingNumber: ''
};

export default AdvancedSearch;
