import * as React from 'react';
import Box from '@mui/material/Box';
import colors from '../css/colors';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SVGIcon } from '@lodgelink/lodgelink-components';
import { Link, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import {
  BottomContentContainerStyle,
  BottomLinksContainerStyle,
  DividerStyle,
  FooterContainerStyle,
  FooterStyle,
  GridContainerStyle,
  LinkPointerStyle,
  SocialLinksContainerStyle
} from './Footer.styles';

const getSocialLinks = () => {
  const socialLinks = [
    {
      href: 'https://twitter.com/lodgelink',
      iconType: 'twitter'
    },
    {
      href: 'https://ca.linkedin.com/company/lodgelink',
      iconType: 'linkedin'
    },
    {
      href: 'https://www.facebook.com/LodgeLink/',
      iconType: 'facebook'
    },
    {
      href: 'https://www.instagram.com/lodgelink/',
      iconType: 'instagram'
    }
  ];
  return socialLinks.map((socialLink) => (
    <Link href={socialLink.href} underline="none">
      <SVGIcon selectedIcon={socialLink.iconType} backgroundColor="transparent" iconColor="#ffffff" />
    </Link>
  ));
};

const getDateYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const Footer = () => {
  return (
    <Box sx={FooterStyle}>
      <Box sx={FooterContainerStyle}>
        <Grid container spacing={2}>
          <Grid xs={2.5} marginLeft={4} marginTop={5}>
            <SVGIcon selectedIcon="lodgelinkwhite" width="140px" height="50px" />
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colors.colorBlue} fontWeight={'bold'}>
                RESOURCES
              </Typography>
              <Link
                color={colors.colorWhite}
                underline="hover"
                sx={LinkPointerStyle}
                href={`https://www.lodgelink.com/onboarding-journey/`}
              >
                Onboarding Journey
              </Link>
            </Box>
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colors.colorBlue} fontWeight={'bold'}>
                CUSTOMER SERVICE
              </Typography>
              <Link color={colors.colorWhite} underline="hover" sx={LinkPointerStyle} href={`tel:+1-844-590-5465`}>
                Toll Free: 1-844-590-5465
              </Link>
              <Link
                color={colors.colorWhite}
                underline="hover"
                sx={LinkPointerStyle}
                href={`https://www.lodgelink.com/contact-us/`}
              >
                Contact Us
              </Link>
            </Box>
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colors.colorBlue} fontWeight={'bold'}>
                FOLLOW LODGELINK
              </Typography>
              <Box sx={SocialLinksContainerStyle}>{getSocialLinks()}</Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={BottomContentContainerStyle}>
          <Divider variant="middle" sx={DividerStyle} />
          <Box sx={BottomLinksContainerStyle}>
            <Typography>{`Copyright ©${getDateYear()} LodgeLink. All rights reserved.`}</Typography>
            <span>&#8226;</span>
            <Link underline="hover" sx={LinkPointerStyle} href={`https://www.lodgelink.com/privacy-policy/`}>
              Privacy Policy
            </Link>
            <span>&#8226;</span>
            <Link underline="hover" sx={LinkPointerStyle} href={`https://www.lodgelink.com/terms-of-use/`}>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
