import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import './Login.styles.js';
import { GENERATE_PARTNER_MAGIC_LINK } from './Login.gql';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  AuthTextStyle,
  LoginContainerStyle,
  LoginFormStyle,
  NotificationContainerStyle,
  StyledButton,
  StyledH1
} from './Login.styles.js';
import colors from '../../layout/css/colors.js';

const Login = () => {
  const [generatePartnerMagicLink, { error, data, loading }] = useMutation(GENERATE_PARTNER_MAGIC_LINK);
  const [sentToEmailAddress, setSentToEmailAddress] = useState('');
  const schema = yup.object().shape({
    emailAddress: yup.string().email('Invalid Email format.').required('Email address is required')
  });
  const formik = useFormik({
    initialValues: {
      emailAddress: ''
    },
    onSubmit: async (values) => {
      try {
        const { emailAddress } = values;
        await generatePartnerMagicLink({
          variables: { emailAddress }
        });
      } catch (error) {
        // Swallow the error
      }
      formik.resetForm();
    },
    validationSchema: schema,
    validateOnChange: false
  });
  const isSent = error || data;
  const renderDialog = () => {
    if (formik.errors.emailAddress) return <StyledH1 color="red">{formik.errors.emailAddress}</StyledH1>;
    else if (error)
      return (
        <>
          <StyledH1>{`Email Address could not be verified.`}</StyledH1>
          <StyledH1>{`Please try with a different Email Address.`}</StyledH1>
        </>
      );
    else
      return (
        <>
          <StyledH1 bold>{`Thank you!`}</StyledH1>
          <StyledH1>
            <span>An authorized link to your PartnerHub has been sent to&nbsp;</span>
            <span style={{ color: colors.colorBlue, fontWeight: 'bold' }}>{sentToEmailAddress}</span>
          </StyledH1>
          <StyledH1>{`Not there?`}</StyledH1>
          <StyledH1>{`Make sure to check your spam folder and email address`}</StyledH1>
        </>
      );
  };

  return (
    <Box sx={LoginContainerStyle}>
      <Box sx={AuthTextStyle}>
        <StyledH1 size={'25px'}>Email Authentication Required&nbsp;</StyledH1>
        <StyledH1 size={'25px'}>
          <Link href="#" underline="always" color={colors.colorBlue}>
            {`(why?)`}
          </Link>
        </StyledH1>
      </Box>
      <Box sx={LoginFormStyle}>
        <TextField
          sx={{ width: '50ch' }}
          id="emailAddress"
          label="Email Address"
          variant="outlined"
          title="emailAddress"
          value={formik.values.emailAddress}
          error={!!formik.errors.emailAddress && formik.submitCount > 0}
          onChange={(e) => formik.setFieldValue('emailAddress', e.target.value)}
        />
        <StyledButton
          variant="contained"
          type="submit"
          onClick={() => {
            setSentToEmailAddress(formik.values.emailAddress)
            formik.handleSubmit();
          }}
        >
          {loading ? <CircularProgress /> : isSent ? `Re-send authorization email` : `Verify email`}
        </StyledButton>
        {(isSent || formik.errors.emailAddress) && <Box sx={NotificationContainerStyle}>{renderDialog()}</Box>}
      </Box>
    </Box>
  );
};

export default Login;
