/**
 * Breakpoint configuration
 */
export default {
  sm: 320,
  md: 640,
  lg: 1024,
  xl: 1200
};

export const thresholds = {
  min: 320,
  max: 1440
};
