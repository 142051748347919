import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './sections/login/Login.page';
import Dashboard from './sections/dashboard/Dashboard.page';
import useToken from './hooks/useToken';
import Authenticate from './sections/authenticate/Authenticate.page';
import Layout from './layout/Layout';
import { RequireAuth } from './hoc/requireAuth/RequireAuth';
import NotFound from './sections/notFound/NotFound.page';

const App = () => {
  const { setToken, resetToken } = useToken();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/authenticate/:token" element={<Layout component={<Authenticate setToken={setToken} />} />} />
        <Route path="/" element={<Layout component={<Authenticate setToken={setToken} />} />} />
        <Route path="/login" element={<Layout component={<Login />} />} />

        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Layout authed resetToken={resetToken} component={<Dashboard />} />
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Layout authed resetToken={resetToken} component={<NotFound />} />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
