import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { SVGIcon } from '@lodgelink/lodgelink-components';
import {
  LayoutAuthedBodyStyle,
  LayoutAuthedRootStyle,
  LayoutBodyContainerStyle,
  LayoutBodyStyle,
  LayoutRootStyle,
  LayoutSideBarStyle,
  FrontDeskBellStyle,
  SideImageStyle,
  LayoutAuthedBodyContainerStyle,
  LogoContainerStyle,
  WelcomeContinerStyle
} from './Layout.styles';
import colors from './css/colors';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { Link, Typography } from '@mui/material';

const Layout = (props) => {
  const propertyName = localStorage.getItem('propertyName');
  const navigate = useNavigate();
  const logout = () => {
    props.resetToken();
    navigate('/login');
  };
  return (
    <>
      <CssBaseline />
      {props.authed ? (
        <Box sx={LayoutAuthedRootStyle}>
          <Header logout={logout} propertyName={propertyName} />
          <Box sx={LayoutAuthedBodyContainerStyle}>
            <Box sx={LayoutSideBarStyle} />
            <Box sx={LayoutAuthedBodyStyle}>{props.component}</Box>
          </Box>
          <Footer />
        </Box>
      ) : (
        <Box sx={LayoutRootStyle}>
          <Link href="#">
            <Box component="img" sx={SideImageStyle} src="/Side-image.jpg" />
          </Link>
          <Box sx={LayoutBodyStyle}>
            <Box sx={LogoContainerStyle}>
              <SVGIcon selectedIcon="lodgelinkcolor" width="210px" height="70px" />
            </Box>
            <Box sx={WelcomeContinerStyle}>
              <Typography color={colors.colorBlack} fontSize={'40px'} fontWeight={'bold'}>
                Welcome to&nbsp;
              </Typography>
              <Typography color={colors.logoColor1} fontSize={'40px'} fontWeight={'bold'}>
                Partner
              </Typography>
              <Typography color={colors.logoColor2} fontSize={'40px'} fontWeight={'bold'}>
                Hub
              </Typography>
            </Box>
            <Box component="img" sx={FrontDeskBellStyle} src="/Front-Desk-Bell.png" />
            <Box sx={LayoutBodyContainerStyle}>{props.component}</Box>
          </Box>
        </Box>
      )}
    </>
  );
};

Layout.propTypes = {
  authed: PropTypes.bool
};

Layout.defaultProps = {
  authed: false
};

export default Layout;
