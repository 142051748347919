/**
 * Use camelCase for variable names to avoid upsetting eslint
 */

//   Primary
export const colorBurgundy = '#694459';
export const colorBurgundyRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(105, 68, 89, ${alpha} )`;
};

export const colorBlack = '#000000';

export const colorGreen = '#00B361';
export const colorGreenRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(0, 179, 97, ${alpha} )`;
};

export const colorBlue = '#53B7B0';
export const colorBlueRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(83, 183, 176, ${alpha} )`;
};

export const colorSand = '#EFEBE2';
export const colorSandRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(239, 235, 226, ${alpha} )`;
};

export const colorWhite = '#FFFFFF';
export const colorWhiteRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(255, 255, 255, ${alpha} )`;
};

//  Secondary
export const colorBlue02 = '#429C95';
export const colorBlue02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(66, 156, 149, ${alpha} )`;
};

export const colorBlue03 = '#5CCBCB';
export const colorBlue03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(92, 203, 203, ${alpha} )`;
};

export const colorBlue04 = '#97DADB';
export const colorBlue04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(151, 218, 219, ${alpha} )`;
};

export const colorBurgundy02 = '#43303B';
export const colorBurgundy02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(67, 48, 59, ${alpha} )`;
};

export const colorSand02 = '#58544A';
export const colorSand02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(88, 84, 74, ${alpha} )`;
};

export const colorSand03 = '#8B8678';
export const colorSand03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(139, 134, 120, ${alpha} )`;
};

export const colorSand04 = '#FDFBF7';
export const colorSand04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(253, 251, 247 , ${alpha} )`;
};

//  Greys
export const colorGrey = '#2D2429';
export const colorGreyRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(45, 36, 41 , ${alpha} )`;
};

export const colorGrey02 = '#333333';
export const colorGrey02Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(51, 51, 51 , ${alpha} )`;
};

export const colorGrey03 = '#8D8D8D';
export const colorGrey03Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(141, 141, 141 , ${alpha} )`;
};

export const colorGrey04 = '#D8D8D8';
export const colorGrey04Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(216, 216, 216 , ${alpha} )`;
};

export const colorGrey05 = '#F6F5F6';
export const colorGrey05Rgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(246, 245, 246 , ${alpha} )`;
};

//  Alerts
export const colorCobalt = '#3185FF';
export const colorCobaltRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(49, 133, 255, ${alpha} )`;
};

export const colorOrange = '#FB6400';
export const colorOrangeRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(251, 100, 0, ${alpha} )`;
};

export const colorRed = '#E80006';
export const colorRedRgba = opacity => {
  const alpha = opacity || 1;
  return `rgba(232, 0, 6, ${alpha} )`;
};

/*  SVG Icon Colors */

//  LodgeLink SVG Color Logo
export const logoColor1 = '#572A31';
export const logoColor2 = '#009D4F';
export const logoColor3 = '#57B6B2';
export const logoColor4 = '#93D500';

//  LodgeLink SVG White Logo
export const logoWhite = '#C6C6C6';

//  Canada Flag Icon
export const canadaFlagRed = '#FF3131';
export const canadaFlagBackground = '#F5F5F5';

// USA Flag Icon
export const usaFlagBlue = '#46467F';
export const usaFlagRed = '#D02F44';

// Australia Flag Icon
export const australiaFlagRed1 = '#FF2E3B';
export const australiaFlagRed2 = '#FC0D1B';
export const australiaFlagBlue = '#0A17A7';

export const flagGrey = '#F0F0F0';

//  Facebook Icon
export const facebookBlue = '#3B5998';

//  LinkedIn Blue
export const linkedInBlue = '#0077B5';

//  Twitter Blue
export const twitterBlue = '#55ACEE';

//  YouTube Red
export const youTubeRed = '#CE1312';

//  Grey Icons
export const iconGrey = '#020303';

//  Icon Grid Green
export const iconGreen = '#CDE4E2';

// Supplier Theme Colours
export const supplier = {
  darkTheme: '#434C54',
  darkerTheme: '#32393F',
  darkestTheme: '#262C31',
  mainTheme: '#467778',
  midTheme: '#499D97',
  highlightTheme: '#70C9C3',
  lightTheme: '#CFEBE9',
  lightText: '#E7E7E7',
  lighterText: '#F5F5F5',
  error: '#f44336'
};

// Admin Theme Colours
export const admin = {
  darkTheme: '#572A31',
  darkerTheme: '#43303B',
  darkestTheme: '#312626',
  mainTheme: '#694459',
  midTheme: '#9D4978',
  highlightTheme: '#C970A2',
  lightTheme: '#EACFDF',
  lightText: '#E7E7E7',
  lighterText: '#F5F5F5',
  error: '#f44336',
  darkError: '#ce362a'
};

export default {
  colorBurgundy,
  colorBurgundyRgba,
  colorBlack,
  colorGreen,
  colorGreenRgba,
  colorBlue,
  colorBlueRgba,
  colorSand,
  colorSandRgba,
  colorWhite,
  colorWhiteRgba,
  colorBlue02,
  colorBlue02Rgba,
  colorBlue03,
  colorBlue03Rgba,
  colorBlue04,
  colorBlue04Rgba,
  colorBurgundy02,
  colorBurgundy02Rgba,
  colorSand02,
  colorSand02Rgba,
  colorSand03,
  colorSand03Rgba,
  colorSand04,
  colorSand04Rgba,
  colorGrey,
  colorGreyRgba,
  colorGrey02,
  colorGrey02Rgba,
  colorGrey03,
  colorGrey03Rgba,
  colorGrey04,
  colorGrey04Rgba,
  colorGrey05,
  colorGrey05Rgba,
  colorCobalt,
  colorCobaltRgba,
  colorOrange,
  colorOrangeRgba,
  colorRed,
  colorRedRgba,
  logoColor1,
  logoColor2,
  logoColor3,
  logoColor4,
  logoWhite,
  canadaFlagRed,
  canadaFlagBackground,
  usaFlagBlue,
  usaFlagRed,
  australiaFlagRed1,
  australiaFlagRed2,
  australiaFlagBlue,
  flagGrey,
  facebookBlue,
  linkedInBlue,
  twitterBlue,
  youTubeRed,
  iconGrey,
  iconGreen,
  supplier,
  admin
};

export const approvals = {
  rejected: '#FF1E1E',
  approved: '#0B9B87',
  pending: '#5CCBCB',
  automated: '#00B361',
  manual: '#694459'
};
