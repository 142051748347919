import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import colors from '../../layout/css/colors';
import {
  ItemStyle,
  LoadingStyle,
  ModalBottomContentStyle,
  ModalHeaderStyle,
  ModalMiddleContentStyle,
  ModalStyle,
  ModalTopBodyStyle,
  ModalTopContentStyle,
  StyledH1,
  StyledH5,
  StyledH6
} from './VCCModal.styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useQuery } from '@apollo/client';
import { QUERY_GET_VCC_DETAILS } from './VCCModal.gql';
import useToken from '../../hooks/useToken';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';

const Item = (props) => {
  return (
    <Box>
      <StyledH6 sx={ItemStyle} size="12px" color={colors.colorGrey03}>
        {props.label}
      </StyledH6>
      <StyledH6 color={colors.colorBurgundy} size="18px">
        {props.children}
      </StyledH6>
    </Box>
  );
};

const getVCCData = (data) => {
  const vcc = get(data, 'partnerGetVCCDetails', []);
  return vcc;
};

const VCCModal = (props) => {
  const { setVCCModalOpen, bookingNumber } = props;
  const handleClose = () => setVCCModalOpen('');
  const { token } = useToken();
  const { data, loading, error } = useQuery(QUERY_GET_VCC_DETAILS, {
    variables: { token, bookingNumber },
    fetchPolicy: 'cache-and-network'
  });
  const vcc = getVCCData(data);

  if (loading)
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={ModalHeaderStyle}>
            <StyledH1 size={'3ch'} color={colors.colorWhite}>
              Virtual Credit Card Authorization
            </StyledH1>
          </Box>
          <Box sx={LoadingStyle}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );

  if (error) {
    return (
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={ModalHeaderStyle}>
            <StyledH1 size={'3ch'} color={colors.colorWhite}>
              Virtual Credit Card Authorization
            </StyledH1>
          </Box>
          <Typography id="modal-modal-title" sx={{ m: 2 }} variant="h6" component="h2" fontWeight="bold">
            Error Occured
          </Typography>
          <Typography id="modal-modal-description" sx={{ m: 2 }} color={colors.colorGrey03} fontWeight="strong">
            {`${error}`}
          </Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <div>
      <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <Box sx={ModalHeaderStyle}>
            <StyledH1 size={'3ch'} color={colors.colorWhite}>
              Virtual Credit Card Authorization
            </StyledH1>
          </Box>
          <Box sx={ModalTopBodyStyle}>
            <Box sx={ModalTopContentStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Booking Details
              </Typography>
              <Grid container spacing={2}>
                <Grid xs={10}>
                  <Item label="Guest Names">{vcc.guestName}</Item>
                </Grid>
                <Grid xs={4}>
                  <Item label="Customer/Company Name">{vcc.customerName}</Item>
                </Grid>
                <Grid xs={3}>
                  <Item label="Booking Number">{vcc.bookingNumber}</Item>
                </Grid>
                <Grid xs={4}>
                  <Item label="Duration">{vcc.duration}</Item>
                </Grid>
              </Grid>
            </Box>
            <Divider variant="middle" sx={{ m: 2 }} />
            <Box sx={ModalTopContentStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Credit Card Information
              </Typography>
              <Grid container spacing={2}>
                <Grid xs={3}>
                  <Item label="Card Holder Name">{vcc.cardholderName}</Item>
                </Grid>
                <Grid xs={3}>
                  <Item label="Card Number">{vcc.cardNumber}</Item>
                </Grid>
                <Grid xs={3}>
                  <Item label="Expiry">{vcc.expiry}</Item>
                </Grid>
                <Grid xs={2}>
                  <Item label="CVV">{vcc.cvv}</Item>
                </Grid>
              </Grid>
            </Box>
            <Divider variant="middle" sx={{ m: 2 }} />
            <Box sx={ModalMiddleContentStyle}>
              <StyledH1 color={colors.colorRed} size={'20px'}>
                <p>
                  If there is an issue with the LodgeLink form of payment please contact us, do not charge the guest.
                </p>
                <p>
                  <strong>support@lodgelink.com</strong> or <strong>1-844-590-5465</strong>
                </p>
              </StyledH1>
            </Box>
            <Box sx={ModalBottomContentStyle}>
              <StyledH5 color={colors.colorGrey02}>
                <p>
                  Please charge the LodgeLink Credit Card for room and tax only. Guests will provide a deposit for
                  incidentals at the time of check in and will be responsible for any additional charges accrued during
                  their stay.
                </p>
                <p>
                  There are instances where LodgeLink is required to cover incidentals and deposits for our clients. In
                  this case, you will be contacted by a LodgeLink Team Member and advised accordingly.
                </p>
                <p>
                  Pre-negotiated corporate rates and discounts apply. Please do not disclose our rates to your guests.
                </p>
                <p>
                  Please refer to your LodgeLink Contract or contact us at{' '}
                  <span style={{ color: colors.colorBlue }}>support@lodgelink.com</span> of you have any questions.
                </p>
                <p>
                  At the time of check out plese send all invoices to{' '}
                  <span style={{ color: colors.colorBlue }}>folios@lodgelink.com</span> and we will invoice the guest
                  separately.
                </p>
                <p>
                  More information <span style={{ color: colors.colorBlue }}>lodgelink.com/becoming-a-supplier</span>
                </p>
              </StyledH5>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

VCCModal.propTypes = {
  setVCCModalOpen: PropTypes.func.isRequired
};

export default VCCModal;
