import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import colors from '../../layout/css/colors';

export const StyledH1 = styled.h1`
  background: white;
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? 'bold' : 'strong')};
  color: ${(props) => props.color ?? 'black'};
  text-align: center;
`;

export const StyledButton = styledMUI(Button)({
  color: colors.colorWhite,
  backgroundColor: colors.logoColor3,
  '&:hover': {
    backgroundColor: colors.logoColor3
  },
  padding: 10,
  width: '50ch !important'
});

export const LoginContainerStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

export const LoginFormStyle = {
  '& > :not(style)': { m: 2 },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const NotificationContainerStyle = {
  p: 1,
  m: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '70ch !important'
};

export const AuthTextStyle = { display: 'flex', flexDirection: 'row' };
