import gql from 'graphql-tag';

export const AUTHENTICATE_PARTNER = gql`
query AuthenticatePartner($token: String!){
    authenticatePartner(token: $token){
      __typename
      message
      token
    }
  }
`;