import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from './AutoComplete';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import debounce from 'lodash/debounce';
import useToken from '../../hooks/useToken';

const GraphAutoComplete = (props) => {
  const { query, queryString, label, inputName, setValue, key, mapper, disabled, initialValue } = props;
  const { token } = useToken();
  //search term state
  const [searchTerm, setSearchTerm] = useState(initialValue ?? '');
  const setSearchTermDebounced = debounce(setSearchTerm, 500);
  //query to get suggestions
  const { data, loading } = useQuery(query, {
    variables: {
      token,
      [inputName]: searchTerm
    },
    fetchPolicy: 'cache-and-network',
    skip: !searchTerm
  });
  const graphData = get(data, queryString, []);
  const displayData = mapper(graphData);
  
  React.useEffect(() => {
    if (initialValue) setValue(displayData[0]);
  }, [data]);

  return (
    <AutoComplete
      key={key}
      loading={loading}
      data={displayData} // search suggestions returned
      initialTerm={searchTerm}
      updateSearchTerm={setSearchTermDebounced}
      label={label}
      setValue={setValue}
      disabled={disabled}
      initial={initialValue ? displayData : undefined}
    />
  );
};

GraphAutoComplete.propTypes = {
  query: PropTypes.shape({}).isRequired,
  queryString: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  key: PropTypes.number.isRequired,
  mapper: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

GraphAutoComplete.defaultProps = {
  disabled: false
};

export default GraphAutoComplete;
