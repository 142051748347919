import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useToken from '../../hooks/useToken';

const Authenticate = (props) => {
  const { setToken } = props;
  const { token: queryToken } = useParams();
  if (queryToken) setToken(queryToken);
  const { token: storageToken } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (storageToken) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [storageToken, navigate]);
};

Authenticate.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Authenticate;
