import { styled as styledMUI } from '@mui/material/styles';
import { colors } from '../../layout/css';
import Button from '@mui/material/Button';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorWhite : colors.admin.highlightTheme,
  backgroundColor: props.primary ? colors.admin.highlightTheme : colors.colorGrey04,
  '&:hover': {
    backgroundColor: props.primary ? colors.admin.lightTheme : colors.colorGrey05
  },
  padding: 10,
  fontWeight: 'strong',
  width: '170px',
  margin: 5
}));

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
};

export const LoadingStyle = { display: 'flex', justifyContent: 'center', width: '100%', m: 2 };

export const otpInputStyle = {
  width: '3rem',
  height: '3rem',
  margin: '0 1rem',
  fontSize: '2rem',
  borderRadius: 4,
  border: '1px solid rgba(0,0,0,0.3)'
};

export const ButtonsContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
};

export const TextComponentRowStyle = {
  display: 'flex',
  flexDirection: 'row'
};