import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { SVGIcon } from '@lodgelink/lodgelink-components';
import {
  LayoutAuthedHeaderStyle,
  StyledH1,
  StyledH2,
  ControlBoxStyle,
  PartnerHubContainerStyle,
  LogoContainerStyle,
  ControlContainerStyle
} from './Header.styles';

import { Button, Typography } from '@mui/material';
import colors from '../css/colors';
export const Header = (props) => {
  const { logout, propertyName } = props;
  return (
    <Box sx={LayoutAuthedHeaderStyle}>
      <Box sx={LogoContainerStyle}>
        <SVGIcon selectedIcon="lodgelinkcolor" width="120px" height="40px" />
      </Box>
      <Box sx={PartnerHubContainerStyle}>
        <Typography color={colors.logoColor1} fontSize={'25px'} fontWeight={'bold'}>
          Partner
        </Typography>
        <Typography color={colors.logoColor2} fontSize={'25px'} fontWeight={'bold'}>
          Hub
        </Typography>
      </Box>
      <Box sx={ControlContainerStyle}>
        <Box sx={ControlBoxStyle}>
          <StyledH2>{propertyName}</StyledH2>
          <Button variant="text" onClick={() => logout()}>
            <Typography sx={{ textDecoration: 'underline' }} color={colors.colorBlue}>
              Sign out
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired
};
