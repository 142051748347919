import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { colors } from '../../layout/css';
import Button from '@mui/material/Button';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorWhite : colors.admin.highlightTheme,
  backgroundColor: props.primary ? colors.admin.highlightTheme : colors.colorGrey04,
  '&:hover': {
    backgroundColor: props.primary ? colors.admin.lightTheme : colors.colorGrey05
  },
  padding: 10,
  fontWeight: 'strong'
}));

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
`;

export const SearchHeaderStyle = {
  '& > :not(style)': { m: 1, p: 1, maxWidth: '30ch' }
};

export const SearchBodyStyle = {
  '& > :not(style)': { margin: 1 },
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'center'
};

export const InputBoxStyle = {
  '& > :not(style)': { margin: 1, width: '47%' },
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center'
};

export const SearchButtonsContainerStyle = {
  '& > :not(style)': { margin: 1, width: '25ch' },
  display: 'flex',
  flexDirection: 'row'
};

export const ControlBoxStyle = {
  display: 'flex',
  justifyContent: 'end'
};
