import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { colors } from '../../layout/css';
import Button from '@mui/material/Button';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorWhite : colors.admin.highlightTheme,
  backgroundColor: props.primary ? colors.admin.highlightTheme : colors.colorGrey04,
  '&:hover': {
    backgroundColor: props.primary ? colors.admin.lightTheme : colors.colorGrey05
  },
  padding: 10,
  fontWeight: 'strong'
}));

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  padding: 3px;
  padding-left: 1ch;
`;

export const StyledH5 = styled.h5`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  margin: 10px;
`;

export const StyledH6 = styled.h6`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
`;

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column'
};

export const ModalHeaderStyle = {
  width: 800,
  height: '50px',
  bgcolor: colors.colorBurgundy
};

export const ModalTopBodyStyle = { m: 1, marginTop: 1 };

export const ModalTopContentStyle = { m: 2, marginTop: 2 };

export const ModalMiddleContentStyle = { m: 1, bgcolor: colors.colorWhite, width: 730, heigh: 100 };

export const ModalBottomContentStyle = { m: 1, p: 1, bgcolor: colors.colorGrey04, width: 760, heigh: 100 };

export const ItemStyle = {
  transform: 'translate(0px, 8px) scale(0.9)'
};

export const LoadingStyle = { display: 'flex', justifyContent: 'center', width: '100%', m: 2 };
