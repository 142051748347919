import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { SVGIcon } from '@lodgelink/lodgelink-components';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../layout/css/colors';
import { LoadingStyle, StyledH1, StyledVCCButton, TableContainerStyle, TableLayoutStyle } from './BookingTable.styles';
import { useQuery } from '@apollo/client';
import { QUERY_SEARCH_BOOKINGS } from './BookingTable.gql';
import useToken from '../../hooks/useToken';
import moment from 'moment';

const columns = [
  { id: 'guests', label: 'Guests Name', minWidth: 170 },
  { id: 'customerName', label: 'Customer/Company Name', minWidth: 170 },
  {
    id: 'bookingNumber',
    label: 'Booking Number',
    minWidth: 170
  },
  {
    id: 'checkin',
    label: 'Check-in Date',
    minWidth: 170
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    minWidth: 170
  },
  {
    id: 'vcc',
    label: 'Virtual Credit Card',
    minWidth: 170
  }
];

const getShowHideGuests = (count, hide) => {
  if (!hide) {
    return (
      <>
        <SVGIcon iconColor={colors.colorBlue} selectedIcon="arrow" rotate={0} width="14px" height="14px" />
        {` Show all guests (${count})`}
      </>
    );
  } else {
    return (
      <>
        <SVGIcon iconColor={colors.colorBlue} selectedIcon="arrow" rotate={180} width="14px" height="14px" />
        {` Hide guests (${count})`}
      </>
    );
  }
};

const getButton = (bookingNumber, openAccess, { setOpen, setVCCOpen }) => {
  if (openAccess === bookingNumber)
    return (
      <StyledVCCButton
        accessed
        onClick={() => {
          setOpen(bookingNumber);
        }}
      >
        View VCC
      </StyledVCCButton>
    );
  return (
    <StyledVCCButton
      onClick={() => {
        setOpen(bookingNumber);
      }}
    >
      Access VCC
    </StyledVCCButton>
  );
};

const getBookingsData = (data) => {
  const bookingCount = get(data, 'partnerSearchBookings.bookingCount', 0);
  const bookings = get(data, 'partnerSearchBookings.bookings', []);
  return {
    bookingCount,
    bookings
  };
};

const BookingTable = (props) => {
  const { setOpen, setVCCOpen, searchInput, setSearchInput, otpDetails, page, setPage } = props;
  const { bookingNumber } = otpDetails;
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [expanded, setExpanded] = useState(false);
  const { token } = useToken();
  const { data, loading, error } = useQuery(QUERY_SEARCH_BOOKINGS, {
    variables: { token, searchInput },
    fetchPolicy: 'cache-and-network'
  });
  const { bookingCount, bookings } = getBookingsData(data);

  const handleExpand = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangePage = (_, newPage) => {
    setSearchInput({
      ...searchInput,
      skip: newPage * rowsPerPage
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = +event.target.value;
    setRowsPerPage(rowsPerPage);
    setSearchInput({
      ...searchInput,
      top: rowsPerPage
    });
    setPage(0);
  };

  const getGuestsList = (guests, id) => {
    const count = guests.length;
    const guestList = guests.map((guest) => {
      if (!guest.firstName && !guest.lastName)
        return <Box>{`Unknown`}</Box>;
      return <Box>{`${guest.firstName} ${guest.lastName}`}</Box>;
    });

    return (
      <Accordion
        sx={{ bgcolor: 'transparent' }}
        expanded={expanded === id}
        onChange={handleExpand(id, setExpanded)}
        disableGutters
        elevation={0}
      >
        <AccordionSummary>
          <Typography sx={{ color: colors.colorBlue }}>
            {expanded === id ? getShowHideGuests(count, true) : getShowHideGuests(count, false)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>{guestList}</Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  const rows = () => {
    return bookings.map((booking) => {
      return {
        id: booking.id,
        guests: getGuestsList(booking.guests, booking.id),
        customerName: booking.customerName,
        bookingNumber: booking.bookingNumber,
        checkin: moment(booking.checkin).utc().format('YYYY-MM-DD'),
        createdAt: moment(booking.createdAt).utc().format('YYYY-MM-DD'),
        vcc: getButton(booking.bookingNumber, bookingNumber, { setOpen, setVCCOpen })
      };
    });
  };

  const renderTableBody = () => {
    return rows().map((row) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align}>
                {column.format && typeof value === 'number' ? column.format(value) : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    if (error) return <Box sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}>{error}</Box>;
    if (loading)
      return (
        <Box sx={LoadingStyle}>
          <CircularProgress />
        </Box>
      );
    return (
      <TableContainer sx={TableContainerStyle}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box margin="10px" marginTop="20px">
      <Box
        bgcolor={colors.colorGrey05}
        height="40px"
        sx={{
          '& > :not(style)': { m: 1, p: 1 }
        }}
      >
        <StyledH1>Results</StyledH1>
      </Box>
      <Paper sx={TableLayoutStyle}>
        {renderTable()}
        <TablePagination
          rowsPerPageOptions={[9, 18, 27]}
          component="div"
          count={bookingCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

BookingTable.propTypes = {
  setOpen: PropTypes.func.isRequired,
  setVCCOpen: PropTypes.func.isRequired,
  searchInput: PropTypes.shape({
    top: PropTypes.number,
    skip: PropTypes.number,
    guestName: PropTypes.string,
    organizationId: PropTypes.number,
    bookingId: PropTypes.number,
    checkin: PropTypes.string,
    createdAt: PropTypes.string,
    todayOnly: PropTypes.bool
  }).isRequired,
  setSearchInput: PropTypes.func.isRequired,
  otpDetails: PropTypes.shape({
    iv: PropTypes.string,
    otp: PropTypes.string,
    verificationCode: PropTypes.string,
    bookingNumber: PropTypes.string,
    retry: PropTypes.bool
  }).isRequired
};

export default BookingTable;
