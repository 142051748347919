import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { colors } from '../../layout/css';
import Button from '@mui/material/Button';
import { colorWhite } from '../../layout/css/colors';

export const StyledVCCButton = styledMUI(Button)((props) => ({
  color: colorWhite,
  backgroundColor: props.accessed ? colors.logoColor2 : colors.logoColor3,
  '&:hover': {
    backgroundColor: colors.colorBlue02Rgba(0.5)
  },
  padding: 10,
  fontWeight: 'strong',
  width: '120px'
}));

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
`;

export const LoadingStyle = { display: 'flex', justifyContent: 'center', m: 2, width: '100%' };

export const TableContainerStyle = { maxHeight: 440 };

export const TableLayoutStyle = { maxWidth: '100%', overflow: 'hidden', borderRadius: 0 };
