import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const AutoComplete = (props) => {
  const { data, loading, initialTerm, updateSearchTerm, label, setValue, key, disabled, initial } = props;
  const [term, setTerm] = useState(initialTerm);
  return (
    <Autocomplete
      // suggestions return from query
      key={key}
      inputValue={term}
      filterOptions={(x) => x}
      freeSolo
      options={data}
      loading={loading} // query loading state
      disabled={disabled}
      onInputChange={(e, newInputValue) => {
        updateSearchTerm(newInputValue);
        setTerm(newInputValue);
      }}
      onChange={(e, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          //update search term state on field change
        />
      )}
    />
  );
};

AutoComplete.propTypes = {
  initialTerm: PropTypes.string.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  key: PropTypes.number.isRequired
};

export default AutoComplete;
