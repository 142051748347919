import styled from 'styled-components';
import { colors } from './css';
import fonts from './css/fonts';

export const LayoutAuthedRootStyle = { fontFamily: fonts.fontRegular };

export const LayoutRootStyle = { fontFamily: fonts.fontRegular };

export const LayoutSideBarStyle = {
  position: 'static',
  zIndex: 500,
  bgcolor: colors.colorBurgundy,
  height: 'calc(100vh+300px)',
  maxWidth: '100px',
  width: '10vh'
};

export const LayoutAuthedBodyStyle = {
  bgcolor: '#e4e4e4',
  marginTop: '90px',
  width: { lg: '100%', md: 'calc(100% - 100px)' }
};

export const LayoutBodyStyle = {
  bgcolor: colors.colorWhite,
  height: '100vh',
  width: 'calc(100% - 400px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center'
};

export const LayoutBodyContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
};

export const LayoutAuthedBodyContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh'
};

export const WelcomeContinerStyle = {
  display: 'flex',
  flexDirection: 'row',
  margin: '10px',
  marginTop: '10px'
};

export const StyledH2 = styled.h2`
  background: white;
  color: ${colors.colorGrey03};
  font-size: 20px;
  font-weight: bold;
`;

export const LogoContainerStyle = {
  margin: '25px',
  marginTop: '200px',
  marginLeft: '50px'
};

export const ControlBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center'
};

export const FrontDeskBellStyle = {
  height: 100,
  width: 100
};

export const SideImageStyle = { position: 'fixed', right: 0, height: 'auto', width: '400px', zIndex: 1000 };
