import colors from '../css/colors';

export const FooterStyle = { position: 'absolute', zIndex: 1000, marginTop: 'auto', width: '100vw', height: '200px', bgcolor: colors.colorGrey02};

export const FooterContainerStyle = { marginLeft: '100px', marginTop: 1 };

export const GridContainerStyle = {
  '& > :not(style)': { margin: 1, fontSize: '13px' },
  m: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
};

export const SocialLinksContainerStyle = { scale: '85%', margin: '0 !important' };

export const BottomContentContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const BottomLinksContainerStyle = {
    '& > :not(style)': { margin: 1, fontSize: '10px', color: colors.colorWhite },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
}

export const LinkPointerStyle = { cursor: 'pointer' };

export const DividerStyle = { width: '70%', m: 1, bgcolor: colors.colorWhite };
